<template>
   <div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body p-3">
               <label class="form-label w-100">
                  <i class="far fa-lock font-12 color-theme me-1"></i> Permissão <span class="color-theme float-end">Pressione 'enter' para adicionar</span>
               </label>
               <input type="text" class="form-control searchInput" v-model="add.searchPerm" placeholder="Pesquisar ou enter para adicionar..." @keyup.enter="setPermissionUser(add.searchPerm)" />
            </div>
         </div>
      </div>

      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body p-3">
               <h6 class="font-16 mx-2 mt-1"><i class="fas fa-angle-right font-15 color-theme me-1"></i> Selecione uma permissão</h6>

               <div id="searchPerm" class="cards-0">
                  <div class="card mb-0 invert hover" v-for="(permissao, index) in listaPermissoes" :key="index" @click="setPermissionUser(permissao)">
                     <div class="card-body text-center p-12 cursor-pointer">
                        <h6 class="m-0 limitador weight-700 font-14">{{ permissao }}</h6>
                     </div>
                  </div>
               </div>

               <p class="font-16 mx-2 text-center" v-if="listaPermissoes == null || listaPermissoes.length == 0">Nenhuma permissão encontrada.</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'AdmJogadores_permissões',
   data: function () {
      return {
         add: {'permissao': false, 'searchPerm': ''}
      }
   },
   computed: {
      ... mapState({
         listaPermissoes: state => state.listaPermissoes,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'add.searchPerm' : function (val) {
         var value = val.toLowerCase()

         $("#searchPerm h6").filter(function () {
            $(this.parentElement.parentElement).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
   methods: {
      setPermissionUser : function(permissao) {
         if (permissao == null || String(permissao).trim().length == 0) {
            this.$toast.fire({
               title: 'Permissão inválida!',
               icon: 'error'
            })

            return
         }

         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Adicionar permissão',
            text: 'Informe a identidade',
            input: 'text',
            inputPlaceholder: 'Identidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Identidade inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Adicionar permissão',
                  text: 'Informe o tempo (Dias)',
                  input: 'text',
                  inputPlaceholder: 'Dias',
                  customClass: {
                     input: 'shadow-none'
                  },
                  confirmButtonText: 'Aplicar',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  showDenyButton: true,
                  denyButtonText: 'Permanente',
                  inputValidator: (value) => {
                     if (!$.isNumeric(value)) {
                        return 'Tempo inválido!'
                     }
                  }
               }).then((result2) => {
                  if (!result2.isDismissed) {
                     ref.$store.dispatch('alternarTelaCarregamento', true)
                     
                     ref.$axios({
                        method: 'post',
                        url: ref.urlRest +'game/setPermissionUser',
                        headers: {
                           'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                        },
                        params:{
                           id: parseInt(result.value),
                           permission: permissao,
                           time: (result2.isConfirmed ? result2.value : 0)
                        }
                     }).then(function () {
                        ref.$toast.fire({
                           icon: 'success',
                           title: 'Permissão adicionada!'
                        })
                     }).catch(function (error) {
                        if (error.response != undefined) {
                           if (error.response.status == 408) {
                              ref.$store.dispatch('deslogarAdmin')

                              ref.$toast.fire({
                                 icon: 'warning',
                                 title: 'Sessão expirada!'
                              });
                           } else {
                              ref.$toast.fire({
                                 icon: 'error',
                                 title: 'Erro: ' + error.response.status
                              })
                           }
                        } else {
                           ref.$toast.fire({
                              icon: 'error',
                              title: error
                           })
                        }
                     }).finally(() => {
                        ref.$store.dispatch('alternarTelaCarregamento', false)
                     })
                  }
               })
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('buscarPermissoes')
   }
}

</script>